import {
  trackSegmentEvent,
  getContextProperties,
  getElementProperties,
  trackDOMEvent,
} from '~/components/shared/tracking/segmentAnalytics';

import { setUpModalsOnMap } from '../../shared/controllers/modalController';

let mapViewEl;

export function loadMap(override = false) {
  if (override || !mapViewEl) {
    mapViewEl = document.querySelector('[data-js-map]');

    // initial load
    if (window.innerWidth >= 768) {
      lazyLoadMap(mapViewEl);
    }
  }
}

function lazyLoadMap(mapTarget) {
  if (typeof mapboxgl !== 'undefined') {
    initializeMap(mapTarget);
  } else {
    const map_script = document.createElement('script');
    const map_styles = document.createElement('link');

    map_script.setAttribute(
      'src',
      'https://api.mapbox.com/mapbox-gl-js/v2.9.1/mapbox-gl.js',
    );
    map_script.setAttribute('data-mapbox-source', '');
    map_script.setAttribute('defer', '');
    map_styles.setAttribute(
      'href',
      'https://api.mapbox.com/mapbox-gl-js/v2.9.1/mapbox-gl.css',
    );
    map_styles.setAttribute('rel', 'stylesheet');

    document.head.appendChild(map_script);
    document.head.appendChild(map_styles);

    map_script.addEventListener(
      'load',
      () => {
        initializeMap(mapTarget);
      },
      { once: true },
    );
  }
}

function removeClassFromNodes(nodes, className) {
  nodes.forEach((el) => {
    el.classList.remove(className);
  });
}

function isInteractive(mapTarget) {
  const isSmallMap = mapTarget.classList.contains('small-map');
  const isNotExpanded = !mapTarget.classList.contains('expanded');
  const isNotMobile = window.innerWidth >= 768;
  return !(isSmallMap && isNotExpanded && isNotMobile);
}

function getMarkerClasses(isFeatured) {
  const classes = ['map-marker'];
  if (isFeatured) {
    classes.push('map-marker--featured');
  }
  return classes;
}

function getOffset(isFeatured) {
  if (isFeatured) {
    return [0, -18];
  } else {
    return [0, -12];
  }
}

// add all markers to the given map using the locations HTML node-list
function addMarkers(mapObj, locations, mapTarget) {
  // set up marker with info window and add events and classes to card to mark as active
  const segmentEvent = mapTarget.dataset.segmentEvent || 'Directory - Map';
  let activeWindow;
  const bounds = mapObj.getBounds();
  locations.forEach((locationCard) => {
    const locData = locationCard.dataset;

    // don't show telemeds on map because some will be far away
    if (locData.telemed != 'true') {
      const hasBoost = locData.hasBoost === 'true';
      const lngLat = [
        parseFloat(locData.longitude),
        parseFloat(locData.latitude),
      ];

      // create the infowindow
      const locationCardNode = locationCard.cloneNode(true);
      locationCardNode.querySelectorAll('[data-src]').forEach((el) => {
        el.src = el.dataset.src;
      });
      const button = locationCardNode.querySelector(
        '.unified-listing-card__primary-button',
      );
      if (button) {
        button.classList.remove('md');
        button.classList.add('sm');
      }

      // move the star rating to the description
      const ratings = locationCardNode.querySelector(
        '.unified-listing-card__rating',
      );
      const details = locationCardNode.querySelector(
        '.primary-content__details',
      );
      if (ratings) {
        details.appendChild(ratings);
      }

      if (locationCardNode.dataset.modalActivator) {
        locationCardNode.dataset.mapModalActivator = true;
      }
      locationCardNode.querySelectorAll('a').forEach((link) => {
        link.dataset.segmentEvent = segmentEvent;
        link.dataset.segmentAction += ' within bubble in map';
        link.addEventListener('click', (e) => {
          trackDOMEvent(e);
        });
      });
      const popup = new mapboxgl.Popup({
        focusAfterOpen: false,
        offset: getOffset(hasBoost),
      });

      // Since the new card's markup differs from the existing card, we need to ensure
      // we handle the styles accordingly
      locationCardNode.dataset.isMapPopup = true;

      popup.setDOMContent(locationCardNode);
      popup.setMaxWidth('unset');

      popup.on('open', () => {
        if (activeWindow) {
          activeWindow.remove();
        }
        activeWindow = popup;
        setUpModalsOnMap();
      });

      popup.on('close', () => {
        activeWindow = null;
      });

      // create the marker
      const el = document.createElement('div');
      el.classList.add(...getMarkerClasses(hasBoost));
      const marker = new mapboxgl.Marker({
        element: el,
      });

      marker.setLngLat(lngLat);
      marker.setPopup(popup);
      marker.addTo(mapObj);

      // extend the bounds to fit the marker
      bounds.extend(lngLat);

      // when marker is clicked open the infowindow and set the card active
      marker.on('click', () => {
        if (!isInteractive(mapTarget)) {
          return;
        }
        trackSegmentEvent(segmentEvent, {
          ...getContextProperties(),
          ...getElementProperties(locationCardNode),
          action: 'click pin',
        });
        // only allow a single window to be open at once
        removeClassFromNodes(locations, 'active');
        locationCard.classList.add('active');
      });

      // when a card is hovered, open the popup
      locationCard.addEventListener('mouseenter', () => {
        if (!isInteractive(mapTarget)) {
          return;
        }
        if (!marker.getPopup().isOpen()) {
          marker.togglePopup();
        }
        el.classList.add('map-marker--grow');
        // only allow a single window to be open at once
        removeClassFromNodes(locations, 'active');
        locationCard.classList.add('active');
      });

      locationCard.addEventListener('mouseleave', () => {
        if (!isInteractive(mapTarget)) {
          return;
        }
        el.classList.remove('map-marker--grow');
      });
      locationCard.classList.add('listing-card');
    }
  });
  mapObj.fitBounds(bounds, { padding: 20 });
}

// Create the new map
function initializeMap(mapTarget) {
  if (!mapTarget) return;
  const accessToken = document.getElementById('envKeys').dataset.mapboxKey;
  const mapData = mapTarget.dataset;
  let center = [0, 0];
  if (
    typeof mapData.noResultsLat !== 'undefined' &&
    typeof mapData.noResultsLong !== 'undefined'
  ) {
    center = [
      parseFloat(mapData.noResultsLong),
      parseFloat(mapData.noResultsLat),
    ];
  }

  let mapParams = {
    accessToken,
    container: mapTarget,
    center,
    style: 'mapbox://styles/mapbox/streets-v11?optimize=true', // style URL
    zoom: 12,
  };

  const locationMap = new mapboxgl.Map(mapParams);

  if (mapData.noResults === '') {
    return;
  }

  const locations = document.querySelectorAll(
    '.unified-listing-card:not(.exclude-from-map)',
  );

  addMarkers(locationMap, locations, mapTarget);

  // We want the map initial zoom to be fixed to the expanded size. So we have to wait until it's initialized, then remove the expanded css to crop it.
  locationMap.on('load', (event) => {
    mapTarget.classList.remove('expanded');
    mapTarget.parentElement.parentElement.classList.remove('expanded');
  });
}
